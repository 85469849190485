import HomePageBanner from "@/components/HomePage/HomePageBanner";
import FindRole from "@/components/Shared/FindRole";
import OurService from "@/components/Shared/OurService";
import CompanySlide from "@/components/Shared/CompanySlider";
import Testimonials from "@/components/Shared/Testimonials";
import SpecialismsStack from "@/components/Shared/SpecialismsStack";
import OperationalCoverage from "@/components/Shared/OperationalCoverage";
import BlogsSection from "@/components/Shared/BlogsSection";
import SEO from "@/components/SEO";
import { TESTIMONIALS_CUSTOM_STYLES } from "@/constants/home";
import localizePath from "@/functions/localizePath";
import useJobs from "@/hooks/useJobs";

export default function HomePage({ locale = "en" }) {
  const jobs = useJobs();

  return (
    <>
      <SEO
        path={localizePath("/", locale)}
        title="Technology Recruitment Specialists | Lawrence Harvey"
        description="Welcome to Lawrence Harvey"
      />
      <main className="bg-purple sm:pt-[160px] sm:min-h-screen homepage">
        <HomePageBanner />
        <SpecialismsStack path="market" />
        <div className="bg-gradientPurpleBlue bg-no-repeat">
          <CompanySlide isFooterExists={true} title="You’re in good company" />
          <OurService
            background="bg-purple-15"
            learnMoreBgColor="transparent"
          />
          <Testimonials customStyles={TESTIMONIALS_CUSTOM_STYLES} />
        </div>
        <div className="bg-white">
          <FindRole path="dynamic1.find" jobs={jobs} />
          <OperationalCoverage
            coverageStyles={{
              color: "text-purple",
              border: "!border-purple",
              background: "bg-purple",
              btn: "white",
              btnStyle: "purple",
              hover: "group-hover/item:text-purple",
              btnColor: "#1B0D34",
              btnBGColor: "transparent",
              hoverBGColor: "#1B0D34",
              hoverColor: "white",
            }}
          />
        </div>
        <BlogsSection
          path="dynamic1.blog"
          title="Industry insights, trends, podcasts and more."
          styles={{
            txt: "text-white",
            active: "white",
            inactive: "purple",
            arrow: "[&>path]:fill-purple [&>path]:stroke-purple",
          }}
        />
      </main>
    </>
  );
}
